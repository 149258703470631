<script setup lang="ts">
import {Vue3Lottie} from "vue3-lottie";
import TeamStats from "~/components/TeamStats.vue";
import type {Trail} from "~/types/Trail";
import type {SerializeObject} from "nitropack";
import type {GameStatsModel} from "~/types/TeamStatsData";

const {t, locale} = useI18n()

const seoTitle = computed(() => t('seoTitle', {city: data?.value?.city.english}))
const seoDescription = computed(() => t('seoDescription', {city: data?.value?.city.english}))

const route = useRoute();
const token = route.query.token;
const emailParam = route.query.emu;

const unlockedTokens = useCookie('unlockedTokens')

const needsEmailVerification = ref<boolean>(!unlockedTokens.value?.includes(token?.toString() || '') && !emailParam);
const {error, data: data,} = await useFetch<GameStatsModel>(`/api/profile?token=${token}`);

const nbTrails = ref<SerializeObject<Trail>[] | null>();

async function fetchNearbyTrails(trailIdentifier: string) {
  const {data: d} = await useFetch(`/api/nearby/${trailIdentifier}?lang=${locale.value}`);
  nbTrails.value = d.value as SerializeObject<Trail>[] | null;
}

if (!error.value) {
  if (data?.value?.trailIdentifier) {
    await fetchNearbyTrails(data.value.trailIdentifier);
  }
}

watch(locale, async () => {
  if (data?.value?.trailIdentifier) {
    await fetchNearbyTrails(data.value.trailIdentifier);
  }
});

useSeoMeta({
  title: seoTitle.value,
  ogTitle: seoTitle.value,
  description: seoDescription.value,
  ogDescription: seoDescription.value,
  ogImage: data?.value?.teams?.[0]?.groupPictureThumbUrl || '/Explorial_Logo.svg',
});
</script>

<template>
  <LockedOverlay v-if="!error && data" :is-locked="needsEmailVerification" :gameCode="data.gameCode"
                 :trail="data.trailIdentifier" @unlock="() => {
      if (unlockedTokens) {
        unlockedTokens = JSON.stringify([token?.toString() || '']);
      } else {
        unlockedTokens = JSON.stringify([...(unlockedTokens ? JSON.parse(unlockedTokens) : []), token?.toString() || '']);
      }
      needsEmailVerification = false
    }">
    <div v-if="data.teams && Array.isArray(data.teams)" class="md:w-1/2">
      <div class="h-52">
        <ClientOnly>
          <div class="relative">
            <Vue3Lottie class="abs-center z-10" animationLink="./Animation - 1718959358212_v2.json" :height="200"
                        :width="200" :loop="false"/>
            <Vue3Lottie class="abs-center z-0" animationLink="./Animation - 1719320637460.json" :height="200"
                        :width="200"/>
          </div>
        </ClientOnly>
      </div>

      <div class="px-10 text-center">
        <h1 class="font-bold text-xl">{{ $t("mainTitle", {city: data.city.english}) }}</h1>
        <p class="text-sm mt-3">{{ $t("mainDescription", {city: data.city.english}) }}</p>
      </div>

      <FomoCouponTimer/>

      <div v-if="data.teams && data.teams.length <= 2" v-for="team in data.teams" :key="team.id" class="my-5">
        <TeamStats :team="team" :game="data"/>
      </div>

      <UAccordion v-if="data.teams && data.teams.length > 2" color="gray" variant="soft" size="xl" class="my-10"
                  :items="data.teams?.map(d => ({ label: `Team: ${d.name}`, data: d }))">
        <template #item="{ item }">
          <TeamStats :team="item.data" :game="data"/>
        </template>
      </UAccordion>

      <br/>

      <FomoCouponTimer/>
      <br/>

      <StatisticsComponent v-if="nbTrails" :title="$t('moreTrailsTitle')"
                           :description="$t('moreTrailsDescription')"
                           class="m-5"
                           :elements="nbTrails.map(t => ({ title: t.cityName, description: t.cityNameSuffix, image: t.image.url, href: t.shareUrl }))"
                           :columns="2" :animate="true">
        <h2 class="text-lg font-bold mt-10">{{ $t('moreTrailsThankYou') }}</h2>
        <p class="text-sm mt-2">{{ $t('moreTrailThankYou2') }}</p>
        <p class="text-sm mt-2">{{ $t('moreTrailThankYou3') }}</p>
        <UButton block class="mt-7 mb-20" :to="$t('shopUrl')" target="_blank">{{ $t('moreTrailsButton') }}</UButton>
      </StatisticsComponent>
    </div>
  </LockedOverlay>


  <div v-if="error" class="p-10">
    <p v-if="error.statusCode == 500">
      <h1 class="text-2xl font-bold">{{ $t('errorOops') }}</h1>
      <p class="text-sm mt-3">{{ $t('errorFailed') }}</p>
    </p>
    <p v-if="error.statusCode == 404">
      <h1 class="text-2xl font-bold">{{ $t('errorOops') }}</h1>
      <p class="text-sm mt-3">{{ $t('errorNotFound') }}</p>
    </p>
  </div>

</template>

<style scoped>
.abs-center {
  position: absolute;
  top: 7rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
