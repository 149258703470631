<script setup lang="ts">
import {intervalToDuration} from "date-fns";

const props = defineProps({
  game: {
    type: Object,
    required: true
  },
  team: {
    type: Object,
    required: true
  },
});

const fastestPlayer = computed(() => {
  return props.team?.players.reduce((prev, current) => (prev.avgSecondsToAnswer < current.avgSecondsToAnswer) ? prev : current);
});

const mostCorrectPlayer = computed(() => {
  return props.team?.players.reduce((prev, current) => (prev.percentCorrect > current.percentCorrect) ? prev : current);
});

const teamAvgPercentCorrect = computed(() => {
  return props.team?.players.reduce((prev, current) => prev + current.percentCorrect, 0) / props.team?.players.length;
});

function getImageThumbUrl(bucketLocation: string) {
  return `https://img.explorial.com/insecure/crop:1200:1200/` + btoa(bucketLocation);
}

function getImageFullUrl(bucketLocation: string) {
  return `https://img.explorial.com/insecure/` + btoa(bucketLocation);
}

</script>

<template>
  <div class="w-full bg-gray-100 p-6 flex flex-col space-y-8">
    <StatisticsComponent :title="$t('teamStatsTitle', {teamName: team?.name})" :description="$t('teamStatsDescription')" :elements="[
      {
        description: $t('teamStatsDayDescription'),
        icon: 'i-heroicons-calendar-days-solid',
        numberedTitle: { date: new Date(team.startDate) }
      },
      {
        description: $t('teamStatsRankDescription', {city: game?.city.english}),
        icon: 'material-symbols:bar-chart',
        numberedTitle: { prefix: `${$t('teamStatsRankPrefix')} `, number: team.leaderBoardPosition, postfix: '.' }
      },
      {
        description: $t('teamStatsPlaytimeDescription'),
        icon: 'ic:outline-access-time',
        numberedTitle: { time: intervalToDuration({ start: new Date(team.startDate), end: new Date(team.endDate) }), postfix: ` ${$t('teamStatsPlaytimeHours')}` }
      },
      {
        description: $t('teamStatsWalkedDescription'),
        icon: 'ion:footsteps-sharp',
        numberedTitle: { number: team.steps + (intervalToDuration({ start: new Date(team.startDate), end: new Date(team.endDate) }).seconds || 0) * 3, postfix: ` ${$t('teamStatsWalkedPostfix')}` }
      }]" :columns="2"/>

    <StatisticsComponent :title="$t('insightsTitle')" :is-insights="true" :description="$t('insightsDescription')" :elements="[
      {
        title: $t('insightsFact1Title', {name: fastestPlayer?.name}),
        description: $t('insightsFact1Description', {name: fastestPlayer?.name, time: Math.round((fastestPlayer?.avgSecondsToAnswer || 0) * 10) / 10}),
      },
      {
        title: $t('insightsFact2Title', {name: mostCorrectPlayer?.name}),
        description: $t('insightsFact2Description', {name: mostCorrectPlayer?.name, amountCorrect: mostCorrectPlayer?.amountCorrect}),
      },
      {
        title: $t('insightsFact3Title', {amountCorrect: Math.round(teamAvgPercentCorrect * 100)}),
        description: $t('insightsFact3Description', {avgPercentCorrect: Math.round(teamAvgPercentCorrect * 100)}),
      }]" :columns="1" :animate="true"/>

    <StatisticsComponent :title="$t('playerRankingTitle')" :description="$t('playerRankingDescription')" :elements="team?.players.map((p, index) =>
    ({
      title: p?.name,
      description: p.points.toLocaleString('de-CH') + ` ${$t('playerRankingPointsPostfix')}`,
      icon: index == 0 ? 'twemoji:1st-place-medal' : index == 1 ? 'twemoji:2nd-place-medal' : index == 2 ? 'twemoji:3rd-place-medal' : ''
    }))" :columns="1" :animate="true"/>

    <StatisticsComponent v-if="team.photos && team.photos.length > 0" :title="$t('teamPicturesTitle')"
                         :description="$t('teamPicturesDescription')"
                         :elements="team.photos.map(p => ({ image: getImageThumbUrl(p.location), imageFullUrl: getImageFullUrl(p.location) }))"
                         :columns="2" :animate="true">
    </StatisticsComponent>
  </div>
</template>

<style scoped></style>