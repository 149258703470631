<script setup lang="ts">
// Utility function to get time difference in seconds
const getTimeDifferenceInSeconds = (startTime: number) => {
    const currentTime = Date.now();
    return Math.max(0, Math.floor((24 * 60 * 60 * 1000 - (currentTime - startTime)) / 1000));
};

// Get or set cookie for countdown
const countdownStartCookie = useCookie('countdownStart');
if (!countdownStartCookie.value) {
    countdownStartCookie.value = Date.now().toString();
}

const countdownStart = parseInt(countdownStartCookie.value, 10);
const remainingTime = getTimeDifferenceInSeconds(countdownStart);

// Countdown logic
const hours = ref(Math.floor((remainingTime % (60 * 60 * 24)) / (60 * 60)));
const minutes = ref(Math.floor((remainingTime % (60 * 60)) / 60));
const seconds = ref(remainingTime % 60);

onMounted(() => {
    setInterval(() => {
        if (seconds.value > 0) {
            seconds.value--;
        } else if (minutes.value > 0) {
            minutes.value--;
            seconds.value = 59;
        } else if (hours.value > 0) {
            hours.value--;
            minutes.value = 59;
            seconds.value = 59;
        }
    }, 1000);
});

const route = useRoute();
const token = route.query.token;
const showCouponParam = route.query.coupon;

const showCoupon = ref<boolean>(showCouponParam);

</script>

<template>
    <div v-if="showCoupon"
        class="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-6 flex flex-col items-center text-center">
        <svg class="w-14 h-14 mb-3" viewBox="0 0 100 100" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#FF9300" />
            <svg x="15" y="15" width="70" height="70" fill="#FFFFFF" viewBox="0 0 464.628 464.628"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M394.33,105.596h-76.136c22.944-22.372,37.216-53.596,37.216-88.096c0-9.665-7.835-17.5-17.5-17.5
      c-44.786,0-84.057,24.045-105.596,59.9c-21.539-35.855-60.81-59.9-105.595-59.9c-9.665,0-17.5,7.835-17.5,17.5
      c0,34.5,14.273,65.724,37.216,88.096H70.298c-9.665,0-17.5,7.835-17.5,17.5v324.033c0,9.665,7.835,17.5,17.5,17.5h324.032
      c9.665,0,17.5-7.835,17.5-17.5V123.096C411.83,113.431,403.995,105.596,394.33,105.596z M376.83,267.612h-127.016v-127.016H376.83
      V267.612z M318.184,37.227c-7.525,32.765-33.378,58.618-66.144,66.143C259.566,70.604,285.419,44.751,318.184,37.227z
       M212.588,103.37c-32.765-7.525-58.618-33.378-66.143-66.143C179.21,44.991,205.063,70.844,212.588,103.37z M214.814,140.597v127.016
      H87.798V140.597H214.814z M87.798,302.613h127.016v127.017H87.798V302.613z M249.814,429.629v-127.016h127.016v127.017H249.814z" />
            </svg>
        </svg>
        <a href="https://explorial.com/offer?wt_coupon=lucky30">
            <h5 class="mb-2 text-2xl font-semibold tracking-tight dark:text-white">{{ $t('fomoTitle') }}</h5>
        </a>
        <p class="mb-3 font-normal dark:text-gray-400" v-html="$t('fomoDescription')"></p>

        <div class="grid auto-cols-max grid-flow-col gap-5 text-center">
            <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                <span class="countdown font-mono text-5xl">
                    <span :style="{ '--value': hours }"></span>
                </span>
              {{ $t('fomoHours') }}
            </div>
            <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                <span class="countdown font-mono text-5xl">
                    <span :style="{ '--value': minutes }"></span>
                </span>
              {{ $t('fomoMinutes') }}
            </div>
            <div class="bg-neutral rounded-box text-neutral-content flex flex-col p-2">
                <span class="countdown font-mono text-5xl">
                    <span :style="{ '--value': seconds }"></span>
                </span>
              {{ $t('fomoSeconds') }}
            </div>
        </div>

        <UButton block class="mt-7 py-4" to="https://explorial.com/offer?wt_coupon=lucky30" target="_blank">{{ $t('fomoClaimButton') }}</UButton>
    </div>
</template>



<style scoped>
/* Your component's styles go here */
</style>