<script setup lang="ts">
import { ref, computed } from 'vue';
import { type Duration, format } from "date-fns";

const props = defineProps<{
  title: string,
  isInsights?: boolean,
  description: string,
  elements: {
    title?: string,
    description?: string,
    icon?: string,
    image?: string,
    imageFullUrl?: string,
    href?: string,
    numberedTitle?: { prefix?: string, number?: number, date?: Date, time?: Duration, postfix?: string }
  }[],
  columns: number,
  animate?: boolean
}>();

const imageError = ref<boolean[]>(props.elements.map(() => false));

const handleImageError = (index: number) => {
  imageError.value[index] = true;
};

const hasValidImages = computed(() => {
  return !(props.elements.some(e => e.image)) || props.elements.some((element, index) => element.image && !imageError.value[index]);
});
</script>

<template>
  <div class="text-center" v-if="hasValidImages">
    <h2 class="text-lg font-bold">
      <span v-if="isInsights" class="bg-gray-400 rounded px-1.5 text-gray-100">{{ $t('aiAbbreviation') }}</span> {{ title }}
    </h2>
    <p class="text-sm mt-2" v-html="description"></p>
    <div :class="`grid grid-cols-${columns} gap-3 mt-5`">
      <div
        v-for="(element, index) in elements"
        :key="index"
        :data-aos="animate ? element.image ? 'fade-up' : 'flip-down' : false"
        :class="`p-4 ${element.image ? 'bg-gray-100' : 'bg-white'} ${columns == 1 ? 'text-left flex-row' : 'text-center flex-col'}`"
      >
        <a :href="element.href" target="_blank" rel="noopener noreferrer">
          <UIcon v-if="element.icon" :class="`h-7 w-7 ${columns == 1 ? 'float-right mt-2' : ''}`" :name="element.icon" dynamic />
          <h3 v-if="element.title && !element.image" class="font-bold">{{ element.title }}</h3>
          <h3 v-if="element.numberedTitle" class="font-bold">
            {{ element.numberedTitle.prefix }}
            <AnimatedNumber v-if="element.numberedTitle.number" :endVal="element.numberedTitle.number" :duration="2"/>
            <span v-if="element.numberedTitle.date"><AnimatedNumber :endVal="parseInt(format(element.numberedTitle.date, 'dd'))" :duration="2" :options="{leadingZero: true}"/>.<AnimatedNumber
                :endVal="parseInt(format(element.numberedTitle.date, 'MM'))" :duration="2" :options="{leadingZero: true}"/>.<AnimatedNumber
                :endVal="parseInt(format(element.numberedTitle.date, 'yyyy'))" :duration="2" :options="{leadingZero: true}" separator=""/>
            </span><span v-if="element.numberedTitle.time"><AnimatedNumber :endVal="(element.numberedTitle.time.days || 0) * 24 + (element.numberedTitle.time.hours || 0)"
                                                                           :duration="2" :options="{leadingZero: false}"/>:<AnimatedNumber
              :endVal="element.numberedTitle.time.minutes || 0" :duration="2" :options="{leadingZero: true}"/>
            </span>{{ element.numberedTitle.postfix }}
          </h3>
          <template v-if="element.image && !imageError[index]">
            <a v-if="element.imageFullUrl" :href="element.imageFullUrl" target="_blank">
              <img :src="element.image" @error="() => handleImageError(index)" />
            </a>
            <img v-else :src="element.image" @error="() => handleImageError(index)" />
            <h3 v-if="element.title" class="font-bold text-md mt-3 mb-4">{{ element.title }}</h3>
            <p v-if="element.description" :class="`text-sm ${columns == 1 ? '' : 'mt-[-15px]'}`">{{ element.description }}</p>
          </template>
          <p v-else-if="element.description" :class="`text-sm ${columns == 1 ? '' : 'mt-[-5px]'}`">{{ element.description }}</p>
        </a>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style scoped></style>
