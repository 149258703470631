<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { CountUp } from 'countup.js';

const props = defineProps({
  endVal: {
    type: Number,
    required: true
  },
  startVal: {
    type: Number,
    default: 0
  },
  duration: {
    type: Number,
    default: 2
  },
  options: {
    type: Object,
    default: () => ({}),
    leadingZero: {
      type: Boolean,
      default: false
    },
  },
  separator: {
    type: String,
    default: '\''
  },
});

const formatNumberWithLeadingZero = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

const number = ref(null);

const displayValue = ref(formatNumberWithLeadingZero(props.startVal));

const createObserver = () => {
  const options = {
    root: null,
    threshold: 0.1
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        animateValue();
        observer.unobserve(number.value);
      }
    });
  }, options);

  observer.observe(number.value);
};

const animateValue = () => {
  const countUp = new CountUp(number.value, props.endVal, {
    startVal: props.startVal,
    duration: props.duration,
    useEasing: true,
    useGrouping: true,
    separator: props.separator,
    decimal: '.',
    ... (props.options.leadingZero ?  {formattingFn: (n: number) => formatNumberWithLeadingZero(n)} : {}),
    ...props.options
  });

  if (!countUp.error) {
    countUp.start(() => {
      if(props.options.leadingZero){
        displayValue.value = formatNumberWithLeadingZero(props.endVal);
      } else {
        displayValue.value = countUp.formatNumber(props.endVal);
      }
    });
  } else {
    console.error(countUp.error);
  }
};

onMounted(() => {
  createObserver();
});
</script>

<template>
  <span ref="number" class="animated-number">
    {{ displayValue }}
  </span>
</template>

<style scoped>

</style>