<script setup lang="ts">
import { defineProps, defineEmits, ref, reactive } from 'vue';
const {t} = useI18n()

const brevoErrorWrongEmail = computed(() => t('brevoErrorWrongEmail'))
const brevoErrorSome = computed(() => t('brevoErrorSome'))
const brevoErrorEmailMissing = computed(() => t('brevoErrorEmailMissing'))

const props = defineProps<{
  isLocked: boolean;
  gameCode: string;
  trail: string;
}>();

const emit = defineEmits(['unlock']);
const errorMessage = ref<string>('');

const state = reactive({
  email: undefined,
});

async function unlock() {
  errorMessage.value = ''; // Reset error message
  if (state.email) {
    try {
      const { error, data: data } = await useFetch(`/api/brevo?email=${state.email as string}&gameCode=${props.gameCode}&trail=${props.trail}`);
      if (data.value && data.value.success) {
        emit('unlock');
      } else {
        errorMessage.value = brevoErrorWrongEmail.value;
        console.log('Failed to unlock:', error);
      }
    } catch (error) {
      errorMessage.value = brevoErrorSome.value;
      console.error('Failed to unlock:', error);
    }
  } else {
    errorMessage.value = brevoErrorEmailMissing.value;
    console.error('Email is required');
  }
}
</script>

<template>
  <div class="w-full h-full relative">
    <div :class="`flex justify-center ${isLocked ? 'h-[90vh] overflow-hidden' : ''}`">
      <slot></slot>
    </div>
    <Transition>
      <div v-if="isLocked" class="fixed inset-0 bg-opacity-gradient overscroll-none z-40"></div>
    </Transition>
    <Transition>
      <div v-if="isLocked" class="fixed bottom-0 bg-gray-100 w-full h-[17rem] pt-14 p-10 z-50">
        <UForm :state="state" @submit="unlock" class="text-center">
          <h3 class="font-bold">{{ $t("overlayLockedText") }}</h3>
          <UInput v-model="state.email" color="white" variant="outline" :placeholder="$t('overlayEmailPlaceholder')" class="mt-4" />
          <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
          <UButton color="primary" class="mt-5" block type="submit">{{$t("overlayUnlockButton")}}</UButton>
        </UForm>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
